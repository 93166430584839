<template>
  <v-container>
    <backArrow
      style="margin-left: 12px; margin-top: 5px; position: initial"
      :action="() => onClickBackArrow()"
    />

    <v-row class="px-3 py-3">
      <v-col cols="12" align-self="start">
        <v-row>
          <v-col cols="12">
            <p class="main-title mb-0 pb-0">Telemedicina</p>
            <p class="mt-2" style="color: var(--v-primary)">
              Confirme a compra
            </p>
          </v-col>
        </v-row>

        <v-card class="mb-7" rounded="lg">
          <v-row>
            <v-col cols="12">
              <p
                style="color: var(--v-primary)"
                class="pl-4 mb-0 font-weight-bold"
              >
                Resumo da sua compra
              </p>
            </v-col>

            <v-col cols="12" class="main-text">
              <p class="pl-4 mb-1">Nome(s) do(s) dependente(s):</p>
              <ul class="mx-2 text-subtitle-2">
                <li v-for="dependent in dependents" :key="dependent.id">
                  {{
                    dependent && dependent.physicalPerson
                      ? dependent.physicalPerson.name
                      : "-"
                  }}
                </li>
              </ul>
            </v-col>

            <v-col cols="8" class="pt-0 mt-0 main-text">
              <p class="pl-4 mb-0 text-truncate">
                Valor unitário.................................................
              </p>
            </v-col>
            <v-col cols="4" class="pt-0 mt-0 main-text">
              <p class="pr-4 mb-0 text-right">R$ {{ unitaryCost }},00</p>
            </v-col>

            <v-col cols="12" class="pt-0 mt-0 main-text">
              <p class="pl-4 mb-1">Cobertura unitária:</p>
              <ul class="mx-2 text-subtitle-2">
                <li>08 consultas grátis por ano de contrato</li>
                <li>Especialidades médicas por vídeo com hora marcada</li>
                <li>Pronto atendimento por vídeo 24h</li>
                <li>Desconto medicamento</li>
              </ul>
            </v-col>

            <v-col cols="8" class="pt-0 mt-0 main-text">
              <p class="pl-4 mb-0 text-truncate">
                Total mensal.................................................
              </p>
            </v-col>
            <v-col cols="4" class="pt-0 mt-0 main-text">
              <p class="pr-4 mb-0 text-right font-weight-bold">
                R$ {{ totalCost }},00
              </p>
            </v-col>

            <v-col cols="5" class="pt-0 mt-0 main-text">
              <p class="pl-4 mb-0 text-truncate">Pagamento..............</p>
            </v-col>
            <v-col cols="7" class="pt-0 mt-0 main-text" style="font-size: 17px">
              <p class="pr-4 mb-0 text-right font-weight-bold">
                Cartão de Crédito
              </p>
            </v-col>

            <v-col cols="12" align-self="center">
              <v-radio-group v-model="acceptTerms" class="py-0 my-0">
                <v-radio class="pl-3 pr-4 py-0 my-0" value="terms">
                  <template v-slot:label>
                    <p class="text-justify my-0">
                      Confirmo a compra e aceito os
                      <a @click="onClickTerms()">Termos e Condições</a>.
                    </p>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="px-4" align-self="end">
        <generic-btn
          style="width: 100%"
          :btn-props="{ disabled: !acceptTerms }"
          :on-click="() => onClickConfirm()"
        >
          Prosseguir
        </generic-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="errorDialog" width="500" persistent>
      <v-card>
        <v-card-text class="pt-8">
          O dependente selecionado já foi ativado no plano telemedicina
          anteriormente. <br /><br />Para prosseguir com a reativação, por favor
          enviar uma solicitação para nossa equipe, através do módulo
          <b>Atendimento</b> (clicar em "+" e na sequência "Demais dúvidas"),
          acessível através do <b>Menu</b> na tela inicial.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onClickConfirmDialog()">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import backArrow from "../../components/covid/back-arrow";
import { mapMutations } from "vuex";
import MedicalAppointmentEventService from "@/services/healthcare/MedicalAppointmentEvent";
import ContractService from "@/services/contract/ContractService";
import UserService from "@/services/security/User";

export default {
  name: "CartManagement",
  components: {
    backArrow,
  },
  data: () => ({
    urlTerms: null,
    acceptTerms: null,
    dependents: [],
    unitaryCost: 7,
    totalCost: 7,
    errorDialog: false,
  }),
  computed: {},
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    saveDependents() {
      if (this.dependents && this.dependents.length) {
        this.loading(true);
        const dependentsToRegister = [];

        this.dependents.forEach((dependent) => {
          const payloadDependent = {
            address: {
              zip:
                dependent &&
                dependent.physicalPerson &&
                dependent.physicalPerson.address
                  ? dependent.physicalPerson.address.addressZip
                  : null,
              active: true,
              address:
                dependent &&
                dependent.physicalPerson &&
                dependent.physicalPerson.address
                  ? dependent.physicalPerson.address.address
                  : null,
              city:
                dependent &&
                dependent.physicalPerson &&
                dependent.physicalPerson.address
                  ? dependent.physicalPerson.address.addressCity
                  : null,
              state:
                dependent &&
                dependent.physicalPerson &&
                dependent.physicalPerson.address
                  ? dependent.physicalPerson.address.addressState
                  : null,
              number:
                dependent &&
                dependent.physicalPerson &&
                dependent.physicalPerson.address
                  ? dependent.physicalPerson.address.addressNumber
                  : null,
              neighborhood:
                dependent &&
                dependent.physicalPerson &&
                dependent.physicalPerson.address
                  ? dependent.physicalPerson.address.addressNeighborhood
                  : null,
              country:
                dependent &&
                dependent.physicalPerson &&
                dependent.physicalPerson.address
                  ? dependent.physicalPerson.address.addressCountry
                  : null,
            },
            name:
              dependent && dependent.physicalPerson
                ? dependent.physicalPerson.name
                : null,
            id:
              dependent && dependent.id
                ? dependent.id
                : null,
            birth:
              dependent && dependent.physicalPerson
                ? dependent.physicalPerson.birthDate
                : null,
            documentNumber:
              dependent && dependent.physicalPerson
                ? dependent.physicalPerson.cpf
                : null,
            email:
              dependent && dependent.physicalPerson
                ? dependent.physicalPerson.email
                : null,
            holderDocumentNumber:
              dependent &&
              dependent.beneficiary &&
              dependent.beneficiary.physicalPerson
                ? dependent.beneficiary.physicalPerson.cpf
                : null,
            phone: {
              number:
                dependent && dependent.physicalPerson
                  ? dependent.physicalPerson.cellphoneNumber
                  : null,
              areaCode:
                dependent && dependent.physicalPerson
                  ? dependent.physicalPerson.cellphoneDDD
                  : null,
            },
          };

          dependentsToRegister.push(payloadDependent);
        });

        this.loading(false);
        this.$router.push({ name: 'telemedicinePayment', params: { dependentsToRegister, cartDependents: this.dependents, totalCost: this.totalCost } })

      }
    },
    async loadDependentTerms() {
      await this.appointmentEventService
        .getURLFromTelemedicineDependentTerms()
        .then((result) => {
          if (result && result.data) {
            this.urlTerms = result.data;
          }
        });
    },
    async onClickConfirm() {
      await this.saveDependents();
    },
    onClickBackArrow() {
      this.$router.push({
        name: "telemedicineDependentsManagement",
        params: { dependents: this.dependents, isSaved: true },
      });
    },
    onClickTerms() {
      if (this.urlTerms) {
        window.open(this.urlTerms, "_blank");
      }
    },
    onClickConfirmDialog() {
      this.errorDialog = false;
      this.$router.push({ name: "conectarComSuaEmpresa" });
    },
  },
  async mounted() {
    this._userService = new UserService();
    this.appointmentEventService = new MedicalAppointmentEventService();
    this.contractService = new ContractService();

    if (this.$route.params && this.$route.params.dependents) {
      this.dependents = this.$route.params.dependents;
      if (this.dependents && this.dependents.length) {
        this.totalCost = this.unitaryCost * this.dependents.length;
      }

      this.loading(true);
      await this.loadDependentTerms();
      this.loading(false);
    } else {
      this.$router.push({ name: "minha-empresa" });
    }
  },
};
</script>

<style>
a {
  font-weight: bold;
  text-decoration: none;
  color: var(--v-primary) !important;
}
</style>
